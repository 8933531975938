<template>
  <div id="faq">
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title class="white--text">Ayuda {{ appDisplayName }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon to="login">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-toolbar>

    <div id="media" class="media" style="min-height: 600px">
      <v-toolbar class="elevation-0 transparent media-toolbar">
        <v-btn-toggle />
        <v-spacer />
        <v-btn-toggle v-model="view">
          <v-btn text value="list">
            <v-icon color="primary">mdi-view-headline</v-icon>
          </v-btn>
          <v-btn text value="grid">
            <v-icon color="primary">mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-divider />
      <div v-if="loading" class="text-center">
        <v-progress-circular :size="60" color="primary" indeterminate />
      </div>
      <div v-if="!loading" class="layout row media-layout">
        <div class="media-content flex transparent">
          <vue-perfect-scrollbar class="media-content--warp">
            <v-container v-if="view === 'grid'" fluid>
              <v-layout wrap class="x-grid-lg">
                <v-flex v-for="archivo in archivos" :key="archivo.id" lg4 sm6 xs12 class="pa-2">
                  <v-card flat tile style="background: #f0f0f0">
                    <v-card-title
                      style="min-height: 80px!important; margin-bottom: 0px; font-size: 16px; font-weight: bold; color: #234182"
                    >
                      {{ archivo.title }}
                    </v-card-title>
                    <v-card-text height="300px" style="margin-top: -5px">
                      <iframe
                        v-if="archivo.provider === 'vimeo'"
                        class="embed-responsive-item"
                        :src="'https://player.vimeo.com/video/' + archivo.video_id + '?title=false'"
                        width="100%"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                      />
                      <iframe
                        v-else-if="archivo.provider === 'pdf'"
                        class="embed-responsive-item"
                        :src="archivo.file_url"
                        width="100%"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                      />
                    </v-card-text>
                    <v-divider />
                    <v-card-actions v-if="archivo.hashtags !== null">
                      <v-chip
                        v-for="(hash, indexhash) in archivo.hashtag"
                        :key="indexhash"
                        small
                        :color="hash.color"
                        text-color="white"
                        >{{ hash.nombre }}</v-chip
                      >
                    </v-card-actions>
                    <v-card-actions v-if="archivo.provider === 'pdf'">
                      <v-spacer />
                      <v-btn icon @click="abrirDireccion(archivo.file_url)">
                        <v-icon color="primary">mdi-open-in-new</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
            <v-layout v-else column>
              <v-list dense class="transparent">
                <v-list-item v-for="archivo in archivos" :key="archivo.id" avatar>
                  <v-list-item-avatar>
                    <v-icon v-if="archivo.provider === 'vimeo'">mdi-movie</v-icon>
                    <v-icon v-else-if="archivo.provider === 'pdf'">mdi-pdf</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="container pl-0">
                      <div class="layout row">
                        <div class="flex">{{ archivo.title }}</div>
                        <v-spacer />
                        <div class="caption">
                          <li v-if="archivo.provider === 'vimeo'">
                            <a
                              class="enlace_video"
                              :href="'https://player.vimeo.com/video/' + archivo.video_id"
                              target="_blank"
                              ><v-icon class="icono_enlace">mdi-play-box-multiple</v-icon></a
                            >
                          </li>
                          <li v-else-if="archivo.provider === 'pdf'">
                            <a class="enlace_video" :href="archivo.file_url" target="_blank"
                              ><v-icon class="icono_enlace">mdi-open-in-new</v-icon></a
                            >
                          </li>
                        </div>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-layout>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <v-footer v-if="mostrarFooter" height="auto">
      <v-card flat tile class="blue darken-4 white--text text-center" style="width: 100%">
        <v-card-text>
          <v-btn icon class="mx-3 white--text" @click="abrirDireccion('https://www.facebook.com/Docline.es/')">
            <v-icon size="24px">fa-facebook</v-icon>
          </v-btn>
          <v-btn icon class="mx-3 white--text" @click="abrirDireccion('https://twitter.com/Docline_')">
            <v-icon size="24px">fa-twitter</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mx-3 white--text"
            @click="abrirDireccion('https://www.youtube.com/channel/UCykR-w1g1eN-h_eoSOgxZww')"
          >
            <v-icon size="24px">fa-youtube</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mx-3 white--text"
            @click="abrirDireccion('https://www.linkedin.com/company-beta/10984777/')"
          >
            <v-icon size="24px">fa-linkedin</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text"> &copy;{{ anio }} — <strong>Docline</strong> </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import Bytes from 'bytes';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mapState } from 'vuex';
import { getFileMenu, getFile } from '@/api/file';
import { getFAQUrl } from '../config/config';

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    type: {
      type: String,
      default: 'image',
    },
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    anio: '2019',
    size: 'lg',
    mostrarFooter: false,
    view: 'grid',
    selectedFile: {
      path: '/static/icon/empty_file.svg',
    },
    imageMime: ['image/jpeg', 'image/png', 'image/svg+xml'],
    archivos: [],
    folders: [],
  }),
  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      appLogo: state => state.app.images.logo,
    }),

    mediaMenu() {
      return getFileMenu;
    },
    files() {
      return getFile();
    },
  },
  created() {
    this.loading = false;

    this.$http.post(getFAQUrl).then(response => {
      this.archivos = response.data;
      if (this.archivos.length > 0) {
        this.loading = false;
        const d = new Date();
        this.anio = d.getFullYear();
        this.mostrarFooter = true;
      } else {
        this.$router.push('/login');
      }
    });
  },

  methods: {
    isImage(file) {
      return this.imageMime.includes(file.fileType);
    },
    mimeIcons(file) {
      return this.imageMime.includes(file.fileType) ? 'mdi-image' : 'mdi-file';
    },
    showDetail(file) {
      this.selectedFile = file;
    },
    fileSize(number) {
      return Bytes.format(number);
    },
    formateDate(string) {
      return string ? new Date(string).toLocaleDateString() : '';
    },
    computeFileImage(file) {
      return this.isImage(file) ? file.path : '/static/icon/file_empty.svg';
    },
    abrirDireccion(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
<style scoped lang="css">
.enlace_video {
  text-decoration: none;
}
.icono_enlace:hover {
  color: #78aabf;
}
#faq {
  background-repeat: repeat;
  background-image: url('/static/faq_bg.png');
}
</style>
